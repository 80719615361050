import React, { useCallback } from 'react'
import { getFocusableChildren } from 'helpers/getters'
import { Message } from 'intl'

import s from './SkipContent.module.css'
import messages from './messages'


type SkipContentProps = {
  contentId: string
}

const SkipContent: React.FunctionComponent<SkipContentProps> = ({ contentId }) => {
  const handleClick = useCallback((event) => {
    event.preventDefault()

    const mainContentEl = document.getElementById(contentId)
    const focusableElements = getFocusableChildren(mainContentEl)
    const firstFocusableEl = focusableElements[0]

    if (firstFocusableEl) {
      firstFocusableEl.focus()
    }
  }, [ contentId ])

  return (
    <a className={s.skipContent} href={`#${contentId}`} onClick={handleClick}>
      <Message value={messages.title} />
    </a>
  )
}


export default SkipContent
